export const getAppointmentList = (data,apptUrl) => {
  return {
    type: "FETCH_APPOINTMENT_LIST",
    payload: data,
    apptUrl:apptUrl,
  };
};

export const getAppointmentNotes = (model) => async (dispatch, getState) => {
  dispatch({
    type: "FETCH_APPOINTMENT_NOTES",
    payload: model,
  });
};

export const updateappointmentAttribute = (data) => {
  return {
    type: "UPDATE_APPOINTMENT_ATTRIBUTES",
    payload: data,
  };
};

export const createAppointmentNotes = (data, callback = null) => {
  return {
    type: "CREATE_APPOINTMENT_NOTES",
    payload: data,
    callback
  };
};

export const getSingleAppointmentNote = (data) => async (dispatch) => {
  dispatch({
    type: "FETCH_SINGLE_APPOINTMENT_NOTE",
    payload: data,
  });
};

export const updateAppointmentNote = (data) => {
  return {
    type: "UPDATE_APPOINTMENT_NOTE",
    payload: data,
  };
};

export const deleteAppointmentNote = (data) => {
  return {
    type: "DELETE_APPOINTMENT_NOTE",
    payload: data,
  };
};

export const shareAppointmentNote = (data) => {
  return {
    type: "SHARE_APPOINTMENT_NOTE",
    payload: data,
  };
};

export const scheduleOutsideAppointments = (data,isAlertNeeded = false) => {
  return {
    type: "APPT_SCHEDULE",
    payload: data,
    is_upload : isAlertNeeded
  };
};

export const scheduleInternalAppointments = (data) => {
  return {
    type: "APPT_INTERNAL_SCHEDULE",
    payload: data,
  };
};

export const updateAppointmentStatus = (data) => {
  return {
    type: "UPDATE_APPOINTMENT_STATUS",
    payload: data,
  };
};

export const getAppointmentPhotos  = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_APPOINTEMENT_PHOTO',
    payload:model
  });
}

export const shareAppointmentPhoto= (data) => {
  return {
      type: 'SHARE_APPOINTEMENT_PHOTO',
      payload: data
  }
}

export const deleteAppointmentPhoto = (data) => {
  return {
      type: 'DELETE_APPOINTEMENT_PHOTO',
      payload: data
  }
}

export const rotateAppointmentPhotos = (data) => {
  return {
      type: 'ROTATE_APPOINTEMENT_PHOTO',
      payload: data
  }
} 

export const getRescheduleAppointmentDetails = (data) =>{
  return {
    type: 'GET_RESCHEDULE_APPOINTMENT_DETAILS',
    payload: data
  }
}

export const rescheduleAppointment = (data) => {
  return {
      type: 'RESCHEDULE_APPOINTMENT',
      payload: data
  };
}

export const updateAppointmentTime = (data,apptId,timeActionType) => {
  return {
    type: "UPDATE_APPOINTMENT_TIME",
    payload: data,
    apptId:apptId,
    timeActionType:timeActionType,
  };
};


export const updateAppointmentService = (data,apptId) => {
  return {
    type: "UPDATE_APPOINTMENT_SERVICE",
    payload: data,
    apptId:apptId
  };
};

export const updateAppointmentData = (data,apptId) => {
  return {
    type: "UPDATE_APPOINTMENT",
    payload: data,
    apptId:apptId
  };
};

export const updateAppointmentStaff = (data,apptId) => {
  return {
    type: "UPDATE_APPOINTMENT_STAFF",
    payload: data,
    apptId:apptId
  };
};
   
export const updateAppointmentIconData = (data,apptId) => {
  return {
    type: "UPDATE_APPOINTMENT_ICON_DATA",
    payload: data,
    apptId:apptId,
  };
};

export const getApptServiceCheckList = (data,checklistUrl) => {
  return {
    type: "FETCH_APPT_SERVICE_CHECKLIST",
    payload: data,
    checklistUrl:checklistUrl,
  };
};

export const storeApptEditFilter = (data) => async (dispatch,getState) =>{
  dispatch({
      type:"STORE_APPT_EDIT_FILTER",
      payload:data
  });
}
export const updateApptServiceItem = (data,apptId,itemId) => {
  return {
    type: "UPDATE_SERVICE_CHECKLIST_ITEM",
    payload: data,
    apptId:apptId,
    itemId:itemId,
  };
};
export const addAppointmentDndSummary = (data) => {
  return {
    type: "ADD_APPOINTMENT_DND",
    payload: data,
  };
};

export const getAutomationApptList = (data,apptUrl) => {
  return {
    type: "FETCH_AUTOMATION_APPT_LIST",
    payload: data,
    apptUrl:apptUrl,
  };
};  

export const initializeTempAppoinment = (data) =>{
  return {
      type: 'INITIALIZE_UPLOAD_TEMP_APPOINTMENT',
      payload: data,
  }
}
export const getTempAppointment = (data) =>{
  return {
      type: 'FETCH_TEMP_APPOINTMENT',
      payload: data,
  }
}
export const removeDndSummary = (data) => {
  return {
    type: "REMOVE_APPOINTMENT_DND",
    payload: data,
  };
};

export const cancelBulkAppointment = (data) => {
  return {
      type: 'CANCEL_BULK_APPOINTMENT',
      payload: data
  };
}

export const completeBulkAppointment = (data) => {
  return {
      type: 'COMPLETE_BULK_APPOINTMENT',
      payload: data
  };
}


export const confirmBulkAppointment = (data) => {
  return {
      type: 'CONFIRM_BULK_APPOINTMENT',
      payload: data
  };
}

export const declineTempAppointment = (data) => {
  return {
      type: 'DELETE_TEMP_APPOINTMENT',
      payload: data
  };
}
export const getSingleAppointment = (data,apptUrl) => {
  return {
    type: "FETCH_SINGLE_APPOINTMENT",
    payload: data,
    apptUrl:apptUrl,
  };
};

export const createServiceRequest = (data) => {
  return {
      type: 'CREATE_SERVICE_REQUEST',
      payload: data
  };
}

export const getAppointmentTask  = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_APPOINTMENT_TASK',
    payload:model
  });
}

export const createSupportTask = (data) => {
  return {
      type: 'CREATE_SUPPORT_TASK',
      payload: data
  };
}

export const deleteSupportTask = (data) => {
  return {
      type: 'DELETE_SUPPORT_TASK',
      payload: data
  };
}

export const updateSupportTask = (data) => {
  return {
      type: 'UPDATE_SUPPORT_TASK',
      payload: data
  };
}

export const acceptServiceRequest = (data) => {
  return {
      type: 'ACCEPT_SERVICE_REQUEST',
      payload: data
  };
}

export const getDropdownSupportTasks = (data) =>{
  return {
      type: 'DROPDOWN_SUPPORT_TASKS',
      payload: data
  }
}

export const completeServiceRequest = (data) => {
  return {
      type: 'COMPLETE_SERVICE_REQUEST',
      payload: data
  };
}

export const cancelServiceRequest = (data) => {
  return {
      type: 'CANCEL_SERVICE_REQUEST',
      payload: data
  };
}

export const updateMultiApptServiceItem = (data,apptId) => {
  return {
    type: "UPDATE_MULTI_SERVICE_CHECKLIST_ITEM",
    payload: data,
    apptId:apptId,
  };
};

export const createSubtasks = (data, callback, showAlerts = true) => {
  return {
    type: "CREATE_SUB_TASKS",
    payload: data,
    callback,
    showAlerts
  };
};

export const assignSubtasksList = (data) => {
  return {
    type: "ASSIGN_SUBTASKS_LIST",
    payload: data
  }
};

export const cancelSubtasks = (data, callback = null) => {
  return {
    type: "CANCEL_SUB_TASKS",
    payload: data,
    callback: callback
  };
};

export const completeSubtasks = (data, callback = null, showAlerts = true) => {
  return {
    type: "COMPLETE_SUB_TASKS",
    payload: data,
    callback: callback
  };
};

export const postponeSubtasks = (data, callback = null) => {
  return {
    type: "POSTPONE_SUB_TASKS",
    payload: data,
    callback: callback
  };
};

export const assignSubtasks = (data) => {
  return {
    type: "CREATE_APPOINTMENT_ASSIGN_SUBTASKS",
    payload: data
  };
};
export const fetchSubtasks = (data) => {
  return {
    type: "FETCH_SUB_TASKS",
    payload: data
  };
};

export const updateSubtasks = (data, callback, showAlerts = true) => {
  return {
    type: "UPDATE_SUB_TASKS",
    payload: data,
    callback,
    showAlerts,
  };
};

export const alertSubtasks = (data) => {
  return {
    type: "ALERT_SUB_TASKS",
    payload: data
  };
};

export const approveSubtasks = (data) => {
  return {
    type: "APPROVE_SUB_TASKS",
    payload: data
  };
};

export const disapproveSubtasks = (data) => {
  return {
    type: "DISAPPROVE_SUB_TASKS",
    payload: data
  };
};

export const convertInternalSubtask = (data) => {
  return {
    type: "CONVERT_INTERNAL_SUB_TASK",
    payload: data
  };
};

export const convertOutsideSubtask = (data) => {
  return {
    type: "CONVERT_OUTSIDE_SUB_TASK",
    payload: data
  };
};

export const initializeSubtaskPhotos = (data) =>{
  return {
    type: 'INITIALIZE_SUB_TASK_PHOTOS',
    payload: data
  };
}

export const getSubtaskPhotos  = (data) => {
  return {
    type: 'FETCH_SUB_TASK_PHOTO',
    payload: data
  };
}

export const deleteSubtaskPhoto  = (data) => {
  return {
    type: 'DELETE_SUB_TASK_PHOTO',
    payload: data
  };
}

export const copyOverSubtaskPhotos  = (data) => {
  return {
    type: 'COPY_OVER_SUB_TASK_PHOTOS',
    payload: data
  };
}

export const getRequestedSubtasks = (data, url) => {
  return {
    type: "FETCH_REQUESTED_SUBTASKS",
    payload: data,
    url: url,
  };
};

export const getAssignedSubtasks = (data, url) => {
  return {
    type: "FETCH_ASSIGNED_SUBTASKS",
    payload: data,
    url: url,
  };
};

export const completeAssignedSubtasks = (data) => {
  return {
    type: "COMPLETE_ASSIGNED_SUBTASKS",
    payload: data,
  };
};

export const getSubtasksApprovalList = (data, url) => {
  return {
    type: "FETCH_SUBTASKS_APPROVAL_LIST",
    payload: data,
    url: url,
  };
};

export const printAppointments = (data) => {
  return {
      type: 'APPOINTMENT_PRINT',
      payload: data,
  };
}

export const exportAppointments = (data) => {
  return {
      type: 'APPOINTMENT_EXPORT',
      payload: data,
  };
}

export const exportServiceCheckList = (data) => {
  return {
      type: 'SERVICE_CHECKLIST_EXPORT',
      payload: data,
  };
}

export const getServiceRequestList = (data,apptUrl) => {
  return {
    type: "FETCH_SERVICE_REQUEST_LIST",
    payload: data,
    apptUrl:apptUrl,
  };
};

export const scheduleRecurringApmts = (data) => {
  return {
    type: "RECURRING_APPT_SCHEDULE",
    payload: data,
  };
};


export const exportPropductivityList = (data) => {
  return {
      type: 'PRODUCTIVITY_LIST_EXPORT',
      payload: data,
  };
}

export const printPropductivityList = (data) => {
  return {
      type: 'PRODUCTIVITY_LIST_PRINT',
      payload: data,
  };
};

export const emailAppointment = (data) => {
  return {
      type: 'APPOINTMENT_EMAIL',
      payload: data,
  };
}

export const updateAppointmentCleaner = (data) => {
  return {
      type: 'CHANGE_APPOINTMENT_CLEANER',
      payload: data,
  };
}

export const convertInternalMultiSubtask = (data) => {
  return {
    type: "CONVERT_INTERNAL_MULTI_SUBTASK",
    payload: data
  };
};

export const storeApptPerPage = (data) => async (dispatch,getState) =>{
  dispatch({
      type:"STORE_APPT_PER_PAGE",
      payload:data
  });
}

export const quickAssignStaff = (data) => {
  return {
    type: "QUICK_ASSIGN_STAFF",
    payload: data,
  };
};

export const updateAppointmentInfoData = (data,apptId) => {
  return {
    type: "UPDATE_APPOINTMENT_INFO",
    payload: data,
    apptId:apptId
  };
};

export const uploadOutsideAppointments = (data) => {
  return {
    type: "APPT_SCHEDULE",
    payload: data,
    is_upload : true
  };
};

export const resetAppointmentInfo = (data) => {
  return {
    type: "RESET_APPOINTMENT_INFO",
    payload: data,
  };
};

export const rollAppointments = (data) => {
  return {
    type: "ROLL_APPOINTMENTS",
    payload: data,
  };
}

export const getRollAppointmentsList = (data) => {
  return {
    type: "GET_ROLL_APPOINTMENTS_LIST",
    payload: data,
  };
}

export const uploadEvolveIntegration = (data) =>{
  return {
      type: 'INITIALIZE_EVOLVE_INTEGRATION',
      payload: data,
  }
}
export const getAppointmentTotal =  (data) => {
  return {
    type: "GET_APPOINTMENT_TOTAL",
    payload: data,
  };
};

export const ScheduleInspectionAppointment =  (data) => {
  return {
    type: "SCHEDULE_INSPECTION_APPOINTMENT",
    payload: data,
  };
};

export const getLogLIst = (data, url) => {
  return {
    type: "FETCH_LOG_LIST",
    payload: data,
    url: url,
  };
};

export const ScheduleMassInspectionAppointment =  (data) => {
  return {
    type: "SCHEDULE_MASS_INSPECTION_APPOINTMENT",
    payload: data,
  };
};

export const getAppointmentAssignStaffList = (data,apptUrl) => {
  return {
    type: "FETCH_APPOINTMENT_ASSIGN_STAFF_LIST",
    payload: data,
    apptUrl:apptUrl,
  }
};

export const bulkPhotoUploadNotify =  (data) => {
  return {
    type: "BULK_PHOTO_UPLOAD_NOTIFY",
    payload: data,
  };
};

export const exportSupportTasks = (data) => {
  return {
      type: 'EXPORT_SUPPORT_TASKS',
      payload: data
  }
};

export const printSupportTasks = (data) => {
  return {
      type: 'PRINT_SUPPORT_TASKS',
      payload: data
  }
};
export const exportSubtasks= (data) =>{
  return {
      type: 'EXPORT_SUBTASKS',
      payload: data
    }
};  

export const exportServiceRequests = (data) => {
  return {
      type: 'EXPORT_SERVICE_REQUESTS',
      payload: data
  }
}
export const exportApptTasks = (data) => {
  return {
      type: 'EXPORT_APPT_TASKS',
      payload: data
  }
}

export const printServiceRequests = (data) => {
  return {
      type: 'PRINT_SERVICE_REQUESTS',
      payload: data
  }
}
export const printSubtasks= (data) =>{
  return {
      type: 'PRINT_SUBTASKS',
      payload: data
  }
}
export const printApptTasks = (data) => {
  return {
      type: 'PRINT_APPT_TASKS',
      payload: data
  }
}

export const reconcileBulkAppointment = (data) => {
  return {
      type: 'RECONCILE_BULK_APPOINTMENT',
      payload: data
  }
}
export const getAppointmentTaskList = (data,apptUrl) =>{
  return {
    type: "FETCH_APPOINTMENT_TASK_LIST",
    payload: data,
    apptUrl:apptUrl,
  };
}

export const editAppointmentTasks = (data) => {
  return {
    type: 'EDIT_APPOINTMENT_TASKS',
    payload: data
  }
}

export const getMultipleSubtaskPhotos  = (data) => {
  return {
    type: 'FETCH_MULTIPLE_SUBTASK_PHOTO',
    payload: data
  };
}

export const getAppointmentStats =  (data) => {
  return {
    type: "GET_APPOINTMENT_SERVICE_STATS",
    payload: data,
  };
};

export const shareAllAppointmentPhotos = (data) => {
  return {
      type: 'SHARE_ALL_APPOINTMENT_PHOTOS',
      payload: data
  }
}

export const getNextArrivalDetails = (data) => {
  return {
    type: "FETCH_NEXT_ARRIVAL_DETAILS",
    payload: data,
  };
};

export const scheduleUploadedAppointments = (data) => {
  return {
    type: "SCHEDULE_UPLOADED_APPTS",
    payload: data,
    is_upload : true
  };
};

export const bulkTurnAroundAppointment = (data) => {
  return {
    type: "BULK_TURN_AROUND_APPOINTMENT",
    payload: data,
  };
};

export const updateCheckinCheckoutTimeStatusCenter = (data) => {
  return {
    type: "UPDATE_CHECKIN_CHECKOUT_TIME_STATUS_CENTER",
    payload: data,
  };
};

export const fetchClNewInventory = (data) => {
  return {
      type: 'FETCH_CL_NEW_INVENTORY',
      payload: data
  };
};

export const fetchPmNewInventory = (data) => {
  return {
      type: 'FETCH_PM_NEW_INVENTORY',
      payload: data
  };
};

export const dismissAppointment = (payload, callbacks) => ({
  type: "DISMISS_APPOINTMENT",
  payload: { ...payload, ...callbacks },
});

export const BulkShareAppointmentPhoto = (data) => {
  return {
    type: "BULK_SHARE_APPOINTMENT_PHOTO",
    payload: data,
  };
};

export const getAppointmentServiceCheckList = (data,checklistUrl) => {
  return {
    type: "FETCH_APPOINTMENT_SERVICE_CHECKLIST",
    payload: data,
    checklistUrl:checklistUrl,
  };
};

export const getAppointmentReservationNotes = (model) => async (dispatch, getState) => {
  dispatch({
    type: "FETCH_APPOINTMENT_RESERVATION_NOTES",
    payload: model,
  });
};

export const updateRecurringApmts = (data) => {
  return {
    type: "RECURRING_APPT_UPDATE",
    payload: data,
  };
};
export const getAppointmentCheckedInOutLogs = (data) => {
  return {
    type: "FETCH_APPOINTMENT_CHECKED_IN_OUT_LOGS",
    payload: data,
  };
}
