const initState = {
  appointments: [],
  appointmentNotes: [],
  note: {},
  appointmentPhotos:[],
  appointmentService:{},
  appointmentDetails:{},
  appointmentStaff:{},
  appointmentEditFilter:{},
  appointmentTask:[],
  subtaskList:[],
  rollApptList:[],
  apptTotal : [],
  appointmentsAssignStaff:[],
  appointmentTasks:{},
  multSubtaskPhotos:[],
  clNewInventory: [],
  pmNewInventory: [],
};

const appointment = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_APPOINTMENT_LIST":
      return {
        ...state,
        appointments: action.payload,
      };

    case "SET_APPOINTMENT_LIST":
      return {
        ...state,
        appointments: action.payload,
      };

    case "CLEAR_APPOINTMENT_LIST":
      return {
        ...state,
        appointments: null,
      };

    case "SET_APPOINTMENT_NOTES": {
      return {
        ...state,
        appointmentNotes: action.payload,
      };
    }

    case "CLEAR_APPOINTMENT_NOTES": {
      return {
        ...state,
        appointmentNotes: [],
      };
    }

    case "SET_SINGLE_APPOINTMENT_NOTES": {
      return {
        ...state,
        note: action.payload.data,
      };
    }

    case "CLEAR_SINGLE_APPOINTMENT_NOTES": {
      return {
        ...state,
        note: {},
      };
    }

    case "SET_APPOINTEMENT_PHOTOS":{
      return{
        ...state,
        appointmentPhotos:action.payload.data
      }
    }

    case "CLEAR_APPOINTMENT_PHOTOS": {
      return {
        ...state,
        appointmentPhotos: [],
      };
    }

    case "SET_APPOINTMENT_SERVICE_PRICE": {
      return {
        ...state,
        appointmentService:action.payload.data,
      };
    }
    case "CLEAR_APPOINTMENT_SERVICE_PRICE": {
      return {
        ...state,
        appointmentService: {},
      };
    }

    case "SET_APPOINTMENT_DETAILS": {
      return {
        ...state,
        appointmentDetails:action.payload,
      };
    }
    case "CLEAR_APPOINTMENT_DETAILS": {
      return {
        ...state,
        appointmentDetails: {},
      };
    }
    case "SET_APPOINTMENT_STAFF": {
      return {
        ...state,
        appointmentStaff:action.payload,
      };
    }
    case "CLEAR_APPOINTMENT_STAFF": {
      return {
        ...state,
        appointmentStaff: {},
      };
    }

    case "SET_APPT_SERVICE_CHECKLIST":
      return {
        ...state,
        checklist: action.payload,
      };

      case "CLEAR_APPT_SERVICE_CHECKLIST": {
        return {
          ...state,
          checklist: {},
        };
      }
    case "STORE_APPT_EDIT_FILTER": {
      return {
        ...state,
        appointmentEditFilter: action.payload,
      };
    }

      case "SET_RESCHEDULE_APPOINTMENT_DETAILS": {
        return {
          ...state,
          rescheduleDetail: action.payload.data,
        };
      }

      case "CLEAR_RESCHEDULE_APPOINTMENT_DETAILS": {
        return {
          ...state,
          rescheduleDetail: [],
        };
      }

      case "SET_AUTOMATION_APPT_LIST":
        return {
          ...state,
          autoAppointments: action.payload,
        };

      case "SET_TEMP_APPOINTMENT_DATA": {
        return {
          ...state,
          AppointmentsUploads: action.payload.data,
        }
      }
      case "CLEAR_TEMP_APPOINTMENT_DATA": {
        return {
          ...state,
          AppointmentsUploads: [],
        }
      }
      case "SET_SINGLE_APPOINTMENT":{
        return {
          ...state,
          singleAppt: action.payload,
        };
      }
      case "CLEAR_AUTOMATION_APPT_LIST":
        return {
          ...state,
          autoAppointments: null,
        };
      case "CLEAR_SINGLE_APPOINTMENT":
        return {
          ...state,
          singleAppt: null,
      };
      case "SET_APPOINTMENT_TASK":{
        return {
          ...state,
          appointmentTask: action.payload.data,
        };
      }
      case "CLEAR_APPOINTMENT_TASK":{
        return {
          ...state,
          appointmentTask: null,
        };
      }

      case "SET_DROPDOWN_SUPPORT_TASKS": {
        return {
          ...state,
          suppoertTaskDropdown: action.payload,
        }
      }

      case "CLEAR_DROPDOWN_SUPPORT_TASKS": {
        return {
          ...state,
          suppoertTaskDropdown: null,
        }
      }

      case "SET_APPOINTMENT_SUBTASKS_LIST":{
        return {
          ...state,
          subtaskList: action.payload.data,
        };
      }

      case "CLEAR_APPOINTMENT_SUBTASKS_LIST":{
        return {
          ...state,
          subtaskList: [],
        };
      }

      case "SET_SUB_TASK": {
        return {
          ...state,
          subtasks: action.payload,
        }
      }

      case "CLEAR_SUB_TASK": {
        return {
          ...state,
          subtasks: null,
        }
      }

      case "SET_SUB_TASK_PHOTOS":{
        return {
          ...state,
          subtaskPhotos: action.payload.data
        }
      }

      case "CLEAR_SUB_TASK_PHOTOS":{
        return {
          ...state,
          subtaskPhotos: [],
        }
      }

      case "SET_REQUESTED_SUBTASKS": {
        return {
          ...state,
          requestedSubtasks: action.payload,
        };
      }

      case "CLEAR_REQUESTED_SUBTASKS": {
        return {
          ...state,
          requestedSubtasks: [],
        };
      }

      case "SET_ASSIGNED_SUBTASKS": {
        return {
          ...state,
          assignedSubtasks: action.payload,
        };
      }

      case "CLEAR_ASSIGNED_SUBTASKS": {
        return {
          ...state,
          assignedSubtasks: [],
        };
      }

      case "SET_SUBTASKS_APPROVAL_LIST": {
        return {
          ...state,
          subtasksApprovalList: action.payload,
        };
      }

      case "CLEAR_SUBTASKS_APPROVAL_LIST": {
        return {
          ...state,
          subtasksApprovalList: [],
        };
      }

      case "SET_SERVICE_REQUEST_LIST":
        return {
          ...state,
          serviceRequests: action.payload,
        };

      case "CLEAR_SERVICE_REQUEST_LIST":
        return {
          ...state,
          serviceRequests: null,
        };
      case "SET_APPOINTMENT_SCHEDULE_STATUS":
        return {
          ...state,
          apptSchedule: action.payload,
        };

      case "CLEAR_APPOINTMENT_SCHEDULE_STATUS":
        return {
          ...state,
          apptSchedule:{},
        };

      case "STORE_APPT_PER_PAGE":
        return {
          ...state,
          appointmentPerPage: action.payload,
        };

      case "SET_ROLL_APPOINTMENTS_LIST":
        return {
          ...state,
          rollApptList: action.payload.data,
        }

      case "CLEAR_ROLL_APPOINTMENTS_LIST":
        return {
          ...state,
          rollApptList: [],
        }
        case "SET_APPOINTMENT_TOTAL":
          return {
            ...state,
            apptTotal: action.payload.data,
          }

        case "CLEAR_APPOINTMENT_TOTAL":
          return {
            ...state,
            apptTotal: [],
          }
          case "SET_LOG_LIST": {
            return {
              ...state,
              logList: action.payload,
            };
          }

          case "CLEAR_LOG_LIST": {
            return {
              ...state,
              logList: [],
            };
          }
          case "SET_APPOINTMENT_ASSIGN_STAFF_LIST":
            return {
              ...state,
              appointmentsAssignStaff: action.payload,
            };

          case "CLEAR_APPOINTMENT_ASSIGN_STAFF_LIST":
            return {
              ...state,
              appointmentsAssignStaff: [],
            };

          case "SET_APPOINTMENT_TASK_LIST":
            return {
              ...state,
              appointmentTasks: action.payload,
            };

          case "CLEAR_APPOINTMENT_TASK_LIST":
            return {
              ...state,
              appointmentTasks: {},
            };

        case "SET_MULTIPLE_SUBTASK_PHOTOS":{
          return {
            ...state,
            multSubtaskPhotos: action.payload.data
          }
        }

        case "CLEAR_MULTIPLE_SUBTASK_PHOTOS":{
          return {
            ...state,
            multSubtaskPhotos: [],
          }
        }

        case "SET_APPOINTMENT_SERVICE_STATS":
          return {
            ...state,
            serviceStats: action.payload.data,
          }

        case "CLEAR_APPOINTMENT_SERVICE_STATS":
          return {
            ...state,
            serviceStats: [],
          }

          case "SET_NEXT_ARRIVAL_DETAILS": {
            return {
              ...state,
              nextArrivalDetails: action.payload.data,
            }
          }

          case "CLEAR_NEXT_ARRIVAL_DETAILS": {
            return {
              ...state,
              nextArrivalDetails: [],
            }
          }
          case 'SET_CL_NEW_INVENTORY':
            return {
                ...state,
                clNewInventory: action.payload.data,
            };
        case 'SET_PM_NEW_INVENTORY':
            return {
                ...state,
                pmNewInventory: action.payload.data,
            };
        case 'CLEAR_CL_NEW_INVENTORY':
            return {
                ...state,
                clNewInventory: null,
            };
        case 'CLEAR_PM_NEW_INVENTORY':
            return {
                ...state,
                pmNewInventory: null,
            };

        case "SET_APPOINTMENT_SERVICE_CHECKLIST":
          return {
            ...state,
            checklist: action.payload,
        };
        case "SET_APPOINTMENT_CHECKED_IN_OUT_LOGS":
          return {
            ...state,
            checkedInOutLogs: action.payload,
        };
        case "CLEAR_APPOINTMENT_CHECKED_IN_OUT_LOGS":
          return {
            ...state,
            checkedInOutLogs: [],
        };

        case "SET_APPOINTMENT_RESERVATION_NOTES": {
          return {
            ...state,
            AppointmentReservationNotes: action.payload,
          };
        }

    default:
      return state;
  }
};

export default appointment;
